import { message } from "antd";
import axios from "axios";
import {trackPromise} from 'react-promise-tracker';

export default class UploadFileServices {



    static uploadPatientFile = (id,inputFile, dataUpdaterFunc = () => { }) => {

        const formData = new FormData();
        formData.append('file',inputFile)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        let resultStatus = true;
        trackPromise(
            axios.post("/api-route/api/v1/service/patient/"+id+"/exam/file/", formData,config)
                .then((response) => {message.success(`File ${inputFile.name} uploaded successfully!`);return response.data})
                .catch(err => { if(err.status==413){message.error(`File ${inputFile.name} size too large!`)};resultStatus = false; })
                .finally(() => {
                    dataUpdaterFunc(resultStatus)
                }

                )
        );

    }


    static getAllFilesForPatient = (id,dataUpdaterFunc = () => { }) => {

        /*
 
            [
    {
        "id": 1,
        "fileName": "bird-banner3-x.png",
        "filePath": "bird-banner3-x.png"
    },
    {
        "id": 2,
        "fileName": "bird-banner3-x.png",
        "filePath": "bird-banner3-x.png"
    }
]
        */


       
        let resultantData = [];
        trackPromise(
            axios.get("/api-route/api/v1/service/patient/"+id+"/exam/file/")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchPatientImagesForUploadTab API: " + err))
                .finally(() => dataUpdaterFunc(resultantData))

        );

    }


    static deleteFilesForPatient = (id,fileId,dataUpdaterFunc = () => { }) => {

        /*
 
            [
    {
        "id": 1,
        "fileName": "bird-banner3-x.png",
        "filePath": "bird-banner3-x.png"
    },
    {
        "id": 2,
        "fileName": "bird-banner3-x.png",
        "filePath": "bird-banner3-x.png"
    }
]
        */


       let resultStatus = true;
        trackPromise(
            axios.delete("/api-route/api/v1/service/patient/"+id+"/exam/file/"+fileId)
                .then(response => response.data)
                .catch(err => { resultStatus = false; })
                .finally(() => {
                    dataUpdaterFunc(resultStatus)
                }

                )
        );

    }




}