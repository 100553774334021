import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import { momentLocal, utcToLocal } from "../components/util/TimeUtil";
import CommonUtil from "../components/util/CommonUtil";

const { Text } = Typography;
export default class AppointmentServices {
    

    static createAppointment = (inputData , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        let errormessage = "";
        trackPromise(
         axios.post("/api-route/api/v1/service/appt",inputData)
        .then(response => response.data)
        .catch(err => {
            if(err.status === 400)errormessage = err.data;
            resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Appointment "+CommonUtil.status_messages.create_success)
            } else {
                message.error(errormessage ?? CommonUtil.status_messages.error)
            }
            
            dataUpdaterFunc(resultStatus)
        }

        )
        
        );

    }

    static updateAppointment = (inputData , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        let errormessage = "";
        trackPromise(
         axios.put("/api-route/api/v1/service/appt",inputData)
        .then(response => response.data)
        .catch(err => {
            if(err.status === 400)errormessage = err.data;
            resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Appointment "+CommonUtil.status_messages.update_success)
            } else {
                message.error( errormessage ??  CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }

     //createClientPatient
    
     static createInstantPatient = (inputData) => {
        return new Promise((resolve, reject) => {
            let resultStatus = true;
            let errormessage = "";
    
            trackPromise(
                axios.post("/api-route/api/v1/service/create-patient", inputData)
                .then(response => {
                    message.success("Created patient " + CommonUtil.status_messages.create_success);
                    resolve(response.data);  // Resolve with the response data
                })
                .catch(err => {
                    if (err.response && err.response.status === 400) {
                        errormessage = err.response.data;
                    } else {
                        errormessage = CommonUtil.status_messages.error;
                    }
                    resultStatus = false;
                    message.error(errormessage);
                    reject(errormessage);  // Reject with the error message
                })
            );
        });
    };
    
    
    ///////

    //createClientPatient
    
    static createClientPatient = (inputData) => {
        return new Promise((resolve, reject) => {
            let resultStatus = true;
            let errormessage = "";
    
            trackPromise(
                axios.post("/api-route/api/v1/service/clientPatients", inputData)
                .then(response => {
                    //message.success("Created client patient " + CommonUtil.status_messages.create_success);
                    resolve(response.data);  // Resolve with the response data
                })
                .catch(err => {
                     console.log("err.response.data.error--",err?.data?.error);
                    if (err?.data && err?.status === 400) {
                        errormessage = err?.data?.error;
                        console.log("err.response.data.error--",err?.data?.error);
                    } else {
                        errormessage = CommonUtil.status_messages.error;
                    }
                    resultStatus = false;
                    message.error(errormessage);
                    reject(errormessage);  // Reject with the error message
                })
            );
        });
    };
    
    
    ///////

    //createClientRegister
    
    static createClientRegister = (inputData) => {
        return new Promise((resolve, reject) => {
            let resultStatus = true;
            let errormessage = "";
    
            trackPromise(
                axios.post("/api-route/api/v1/service/clientRegister", inputData)
                .then(response => {
                    message.success("Added New Client Sucessfully!");
                    resolve(response.data);  // Resolve with the response data
                })
                .catch(err => {
                    if (err.response && err.response.status === 400) {
                        errormessage = err.response.data;
                    } else {
                        errormessage = CommonUtil.status_messages.error;
                    }
                    resultStatus = false;
                    message.error(errormessage);
                    reject(errormessage);  // Reject with the error message
                })
            );
        });
    };
    
    
    ///////
    //createJWTToken
    
    static createJWTtoken = (inputData) => {
        return new Promise((resolve, reject) => {
            let resultStatus = true;
            let errormessage = "";
    
            trackPromise(
                axios.post("/api-route/api/v1/service/createJwtToken", inputData)
                .then(response => {
                    //message.success("Created jwt " + CommonUtil.status_messages.create_success);
                    resolve(response.data);  // Resolve with the response data
                })
                .catch(err => {
                    if (err.response && err.response.status === 400) {
                        errormessage = err.response.data;
                    } else {
                        errormessage = CommonUtil.status_messages.error;
                    }
                    resultStatus = false;
                    message.error(errormessage);
                    reject(errormessage);  // Reject with the error message
                })
            );
        });
    };
    
    
    ///////

    static getUpcomingAppointmentForAll = (dataUpdaterFunc=()=>{}) => {
        
        dataUpdaterFunc([]);
        let resultantData = [
  

            {
              apptId: 2,
              stTime: "2021-08-T09:30:00",
              dropOff: true,
              apptTypeName: 'Dental',
              patientFirstName: "Charlie",
              patientLastName: "Bryan",
              endTime: "2021-03-08T10:15:00",
              apptStatusName: "CONFIRMED",
              notes: 'Bad breath, need to get a dental and potentially teeth pulled.',
          
            },
            {
              apptId: 3,
              stTime: "2021-03-08T21:20:00",
              dropOff: false,
              notes: "Test create Appointment",
              patientFirstName: "Woodhouse1",
              patientLastName: "Sikkayan",
              apptTypeName: "Wellness",
              apptStatusName: "Unconfirmed",
              endTime: "2021-03-08T22:05:00",
              url: `url(http://${window.location.host}/whskrwoodhouse1.jpg)`
          
            },
          
          
          
          ]
        trackPromise(
        axios.get("/api-route/api/v1/service/appt-upcoming/all")
        .then(response => resultantData=response.data)
            .catch(err => {})
            .finally(() => {
                let outputData = resultantData.map(  k => ({...k,displaytime :utcToLocal(k.stTime,"yyyy-MM-DDTHH:mm").format("hh:mmA") + " - " + utcToLocal(k.stTime,"yyyy-MM-DDTHH:mm").add(k.duration,"minutes").format("hh:mmA")}));
                dataUpdaterFunc( outputData);

            })
        );

   }

static eventInputData(obj) {
    let displaytime,start,end,displayday="";
    let colObj={
        Wellness : {backgroundColor: '#afd3d4',borderLeft:'2px solid #008489' },
        Dental : {backgroundColor: '#d9c7fe',borderLeft:'2px solid #9966FF' },
        Euthanasia : {backgroundColor: '#ffbdc2',borderLeft:'2px solid #E83151' },
        LunchBreak : {backgroundColor: '#cbcbcb',borderLeft:'2px solid #717171' },
        Surgery : {backgroundColor: '#aed3f3',borderLeft:'2px solid #0483DD' },
        Emergency : {backgroundColor: '#FFF8E1',borderLeft:'2px solid #FF8F00' },
        Exam : {backgroundColor: '#b7e8be',borderLeft:'2px solid #14BC49' },
        Boarding : {backgroundColor: '#C8D4EA',borderLeft:'2px solid #2F4D86' },
        Block : {backgroundColor: '#D7D7D7',borderLeft:'2px solid #717171' },
        Others : {backgroundColor: '#D7D7D7',borderLeft:'2px solid #717171' },
        Grooming : {backgroundColor: '#ADF0FF',borderLeft:'2px solid #00B7E0' },
        "Lunch Break" : {backgroundColor: '#D7D7D7',borderLeft:'2px solid #717171' },
        "Block Off" : {backgroundColor: '#D7D7D7',borderLeft:'2px solid #717171' }
    }
    let title= <Text className="calEventName"> {obj.firstName + " " + obj.lastName} </Text>;
    let status = obj.status;
    if(obj.type==='Lunch Break'){
         title = <span><Text className="calEventName"> Block</Text> <br/><Text className="calEventStatus"> { obj.type}</Text></span>;
         displaytime = utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").format("hh:mmA") + " - " + utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").add(obj.duration,"minutes").format("hh:mmA");
         start = utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").toDate();
         end =   utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").add(obj.duration,"minutes").toDate();
    }else if (obj.type==='Block Off') {
         title = <span><Text className="calEventName"> Block</Text> <br/><Text className="calEventStatus"> { obj.notes}</Text></span>;
         displaytime = utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").format("MM/DD/YYYY") + " - " + utcToLocal(obj.endTime,"yyyy-MM-DDTHH:mm").format("MM/DD/YYYY");
         displayday = obj.allDay ? "All Day" : utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").format("hh:mmA") + " - " + utcToLocal(obj.endTime,"yyyy-MM-DDTHH:mm").format("hh:mmA");
         start = utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").toDate();
         end =   utcToLocal(obj.endTime,"yyyy-MM-DDTHH:mm").toDate();
         status = "Block Off"
    } else {
         title = <span><Text className="calEventName"> {obj.firstName + " " + obj.lastName} </Text> <br/><Text className="calEventStatus"> {obj.type + "-" + obj.status}</Text></span>;
         displaytime = utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").format("hh:mmA") + " - " + utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").add(obj.duration,"minutes").format("hh:mmA");
         start = utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").toDate();
         end =   utcToLocal(obj.stTime,"yyyy-MM-DDTHH:mm").add(obj.duration,"minutes").toDate();
        }
    
    
    
    let tooltipTitle = obj.firstName + " " + obj.lastName;
    let id = obj.apptId;
     
    
    
    //doctor View
    let resourceId = obj.providerId;
    return {title,id,status,start,end,displaytime,colObj:colObj[obj.type],tooltipTitle,resourceId,displayday};
}

   static getAppointmentForCalendar = (startDate,type,dataUpdaterFunc=()=>{}) => {
    dataUpdaterFunc([]);
    let queryParams =" ";
    // let diff = type==="week" ? 7 : 1 ;
    //let startTime = "";
    if(startDate){
        let startTime = "";
        let endTime = "";

        if(type === "week"){
            startTime = momentLocal(startDate).startOf('week').format("YYYY-MM-DD")+ " 00:00:00";
            endTime= momentLocal(startDate).endOf('week').format("YYYY-MM-DD") + " 23:59:59";
        }else {
            startTime = momentLocal(startDate).format("YYYY-MM-DD")+ " 00:00:00";
            endTime= momentLocal(startDate).format("YYYY-MM-DD") + " 23:59:59";
        }
        // let startTime = moment(startDate).format("YYYY-MM-DD")+ " 00:00:00";
        // let  endTime= moment(startDate).add(diff,"days").format("YYYY-MM-DD") + " 23:59:59";
        // if(nextButton && nextButton === 'NEXT'){
            
        // } else {
        //     startTime = moment(endDate).add(diff,"days").format("YYYY-MM-DD") + " 00:00:00";
        // }

        queryParams = "?stDate="+startTime+"&endDate="+endTime;
    
    }
    let resultantData = [];
  
 

    trackPromise(
        axios.get("/api-route/api/v1/service/appt-usr/all"+queryParams)
        .then(response => resultantData=response.data)
            .catch(err => {})
            .finally(() => {
                let outputData = resultantData.map(  k => ({...k,...this.eventInputData(k)}));
                dataUpdaterFunc( outputData);

            })
        );


   }

   static fetchAllAvailableTimeForDR = (doctorId,date,timetype,dataUpdaterFunc = () => { }) => {
    //dataUpdaterFunc([]);
    //let resultantData = [{ "speciesId": 1, "speciesName": "Canine" }, { "speciesId": 2, "speciesName": "Equine" }];
    let resultantData = [];
    trackPromise(
        axios.get("/api-route/api/v1/service/appt/usr/"+doctorId+"/open?date="+date+"&type="+timetype)
            .then(response => resultantData = response.data)
            .catch(err => {})
            .finally(() => dataUpdaterFunc(resultantData))

    );
}

static fetchAllAvailableTimeForDRInMonth = (doctorId,timetype,dateRange,dataUpdaterFunc = () => { }) => {
    //dataUpdaterFunc([]);
    //let resultantData = 
    
    let resultantData = {};
    
    trackPromise(
        axios.get(`/api-route/api/v1/service/appt/usr/${doctorId}/open-month?type=${timetype}&stDate=${dateRange[0]}&endDate=${dateRange[1]}`)
            .then(response => resultantData = response.data)
            .catch(err => {})
            .finally(() => {
                for(let data in resultantData){
                    resultantData[data] = resultantData[data].map( k => momentLocal(k,"hh:mm A").format("hh:mm A"));
                }
                dataUpdaterFunc(resultantData)

            })
            );
}

static fetchAllVets = (dataUpdaterFunc = () => { }) => {
    //dataUpdaterFunc([]);
    //let resultantData = [{ "speciesId": 1, "speciesName": "Canine" }, { "speciesId": 2, "speciesName": "Equine" }];
    let resultantData = [];
    
        axios.get("/api-route/api/v1/service/usr-vets")
            .then(response => resultantData = response.data)
            .catch(err => {})
            .finally(() => dataUpdaterFunc(resultantData))

    
}

static fetchWeightTrendForPatient = (patientId,dataUpdaterFunc = () => { }) => {
    //dataUpdaterFunc([]);
    //let resultantData = [{ "speciesId": 1, "speciesName": "Canine" }, { "speciesId": 2, "speciesName": "Equine" }];
    let resultantData = [];
    trackPromise(
        axios.get("/api-route/api/v1/service/patient/"+patientId+"/wt-trend")
            .then(response => resultantData = response.data)
            .catch(err => {})
            .finally(() => dataUpdaterFunc(resultantData))

    );
}


static fetchAppointmentModalInfo = (apptId,dataUpdaterFunc = () => { }) => {
    //dataUpdaterFunc([]);
    //let resultantData = {"patient":{"fName":"Chloe","breed":"Aegean"},"provider":{"fName":"Dr. Carla","lName":"Politte"},"client":{"fName":"Hayley","lName":"Grove","email":"hayleymgrove@gmail.com","mobile":"1212121212","altPhone":"3342231234"}};
    let resultantData = [];
    trackPromise(
        axios.get("/api-route/api/v1/service/appt/"+apptId+"/calendar/view")
            .then(response => resultantData = response.data)
            .catch(err => {})
            .finally(() => dataUpdaterFunc(resultantData) )

    );
}

static fetchAppointmentSoapDetails = (apptId,dataUpdaterFunc = () => { }) => {
   let resultantData = null;
   
    trackPromise(
        axios.get("/api-route/api/v1/service/exam-details/"+apptId)
            .then(response => resultantData = response.data)
            .catch(err => {})
            .finally(() =>
                {
                if(resultantData){


                
                    resultantData.numeric = resultantData.numeric?resultantData.numeric.reduce((obj, item) => ({ ...obj, [item.attrId]: item.value }), {}):{};
                    resultantData.categorical = resultantData.categorical?resultantData.categorical.reduce((obj, item) => ({ ...obj, [item.attrId]: item.value }), {}):{};
                    resultantData.freeform = resultantData.freeform?resultantData.freeform.reduce((obj, item) => ({ ...obj, [item.attrId]: item.value }), {}):{};
                }
                dataUpdaterFunc(resultantData) }
             )

    );
}

static fetchPatientAppointmentHistoryDetails = (patientId,dataUpdaterFunc = () => { }) => {
    let resultantData = null;
    
     trackPromise(
         axios.get("/api-route/api/v1/service/exam-history/patient/"+patientId)
             .then(response => resultantData = response.data)
             .catch(err => {})
             .finally(() =>
                 {
                
                 dataUpdaterFunc(resultantData) }
              )
 
     );
 }
 


static fetchPrimaryDoctor = (patientId) => {
    
   
       return  axios.get("/api-route/api/v1/service/patient/"+patientId+"/py-dr");
            

    
}

static createAppointmentDetails = (inputData , dataUpdaterFunc=()=>{}) => {
    //console.log(" the input data ==>",inputData);
   
    let resultStatus = true;
    let errormessage = "";
    trackPromise(
        axios({
            method: 'post',
            url: '/api-route/api/v1/service/exam-details',
            data: inputData
        })
     
    .then(response => response.data)
    .catch(err => {
        if(err.status === 400)errormessage = err.data;
        resultStatus=false;})
    .finally( () => {
        if(resultStatus){
            message.success("Appointment Details "+CommonUtil.status_messages.create_success)
        } else {
            message.error(errormessage ??  CommonUtil.status_messages.error)
        }
     
        dataUpdaterFunc(resultStatus)
    }

    )
    );



}

static editAppointmentDetails = (inputData , dataUpdaterFunc=()=>{}) => {
    //console.log(" the input data ==>",inputData);
    let resultStatus = true;
    let errormessage = "";
    trackPromise(
     axios.put("/api-route/api/v1/service/exam-details",inputData)
    .then(response => response.data)
    .catch(err => {
        if(err.status === 400)errormessage = err.data;
        resultStatus=false;})
    .finally( () => {
        if(resultStatus){
            message.success("Appointment Details "+CommonUtil.status_messages.update_success)
        } else {
            message.error(errormessage ?? CommonUtil.status_messages.error)
        }
     
        dataUpdaterFunc(resultStatus)
    }

    )
    );



}

static getUpcomingAppointmentOverview = (dataUpdaterFunc=()=>{}) => {
        
    dataUpdaterFunc([]);
    let resultantData = [
        // {
        //     "name": "Wellness",
        //     "percent": 62.1
        // },
        // {
        //     "name": "Surgery",
        //     "percent": 2.54
        // },
        // {
        //     "name": "Dental",
        //     "percent": 3.27
        // },
        // {
        //     "name": "Exam",
        //     "percent": 29.64
        // },
        // {
        //     "name": "Emergency",
        //     "percent": 1.39
        // },
        // {
        //     "name": "Euthanasia",
        //     "percent": 0.73
        // },
        // {
        //     "name": "Boarding",
        //     "percent": 0.03
        // },
        // {
        //     "name": "Block",
        //     "percent": 0.28
        // }
    ]
    trackPromise(
    axios.get("/api-route/api/v1/service/appt-overview")
    .then(response => resultantData=response.data)
        .catch(err => {})
        .finally(() => {
           
            dataUpdaterFunc(resultantData);

        })
    );

}

static deleteAppointmentById = (id , dataUpdaterFunc=()=>{}) => {
    //console.log(" the input data ==>",inputData);
    let resultStatus = true;
    let errormessage = "";
    trackPromise(
     axios.delete("/api-route/api/v1/service/appt/cancel/"+id)
    .then(response => response.data)
    .catch(err => {
        if(err.status === 400)errormessage = err.data;
        resultStatus=false;})
    .finally( () => {
        if(resultStatus){
            message.success("Appointment  "+CommonUtil.status_messages.delete_success)
        } else {
            message.error(errormessage ?? CommonUtil.status_messages.error)
        }
     
        dataUpdaterFunc(resultStatus)
    }

    )
   
    );



}

static updateAppointmentStatus = (appt_id ,status_id, dataUpdaterFunc=()=>{}) => {
    let resultStatus = true;
    let errormessage = "";
    trackPromise(
     axios.put(`/api-route/api/v1/service/appt/${appt_id}/status/${status_id}`)
    .then(response => response.data)
    .catch(err => {
        if(err.status === 400)errormessage = err.data;
        resultStatus=false;})
    .finally( () => {
        if(resultStatus){
            message.success("Appointment status "+CommonUtil.status_messages.update_success)
        } else {
            message.error(errormessage ?? CommonUtil.status_messages.error)
        }
     
        dataUpdaterFunc(resultStatus)
    }

    )
    
    );

}


static updateAppointmentTimeById = (id,inputData , dataUpdaterFunc=()=>{}) => {
    let resultStatus = true;
    let errormessage = "";
    // {
    //     "stTime": "yyyy-MM-dd HH:mm"
    //   }
    trackPromise(
     axios.put("/api-route/api/v1/service/appt/date/"+id , inputData)
    .then(response => response.data)
    .catch(err => {
        if(err.status === 400)errormessage = err.data;
        resultStatus=false;})
    .finally( () => {
        if(resultStatus){
            message.success("Appointment "+CommonUtil.status_messages.update_success)
        } else {
            message.error(errormessage ?? CommonUtil.status_messages.error)
        }
     
        dataUpdaterFunc(resultStatus)
    }

    )
   
    );

}


static getAppointmentById= (id,dataUpdaterFunc=()=>{}) => {
        
   // dataUpdaterFunc([]);
    let resultantData = {}
    // {
    //     "apptId": 58,
    //     "patientId": 6041,
    //     "branchId": 4,
    //     "providerId": 15,
    //     "apptTypeCd": "WL",
    //     "apptStatusId": 2,
    //     "stTime": "2021-06-30T15:30:00",
    //     "duration": 30,
    //     "dropOff": "N",
    //     "isRepeat": "",
    //     "notes": "sd",
    //     "createdBy": 15,
    //     "createdTime": "2021-06-30T10:07:10",
    //     "updatedBy": 15,
    //     "updatedTime": "2021-06-30T10:07:10"
    // }
    trackPromise(
    axios.get("/api-route/api/v1/service/appt/"+id)
    .then(response => resultantData=response.data)
        .catch(err => {})
        .finally(() => {
           
            dataUpdaterFunc(resultantData);

        })
    );

}


static createAppointmentBlockOff = (inputData , dataUpdaterFunc=()=>{}) => {
    // {
    //     "reason" : "Personal reason",
    //     "stDate" : "2021-10-14",
    //     "endDate" : "2021-10-15",
    //     "stTime" : "10:00",
    //     "endTime" : "12:00"
    // }
    //for all day 

    // {
    //     "reason" : "Personal reason",
    //     "stDate" : "2021-10-14",
    //     "endDate" : "2021-10-15",
    //     "allDay" : true
    // }
    let resultStatus = true;
    let errormessage = "";
    trackPromise(
     axios.post("/api-route/api/v1/service/appt-block",inputData)
    .then(response => response.data)
    .catch(err => {
        if(err.status === 400)errormessage = err.data;
        resultStatus=false;})
    .finally( () => {
        if(resultStatus){
            message.success("Block Off "+CommonUtil.status_messages.create_success)
        } else {
            message.error(  errormessage ?? CommonUtil.status_messages.error)
        }
     
        dataUpdaterFunc(resultStatus)
    }

    )
   
    );



}

static UpdateAppointmentBlockOff = (inputData , dataUpdaterFunc=()=>{}) => {
    // {
    //     "reason" : "Personal reason",
    //     "stDate" : "2021-10-14",
    //     "endDate" : "2021-10-15",
    //     "stTime" : "10:00",
    //     "endTime" : "12:00"
    // }

    //for all day 

    // {
    //     "reason" : "Personal reason",
    //     "stDate" : "2021-10-14",
    //     "endDate" : "2021-10-15",
    //     "allDay" : true
    // }


    let resultStatus = true;
    let errormessage = "";
    trackPromise(
     axios.put("/api-route/api/v1/service/appt-block",inputData)
    .then(response => response.data)
    .catch(err => {
        if(err.status === 400)errormessage = err.data;
        resultStatus=false;})
    .finally( () => {
        if(resultStatus){
            message.success("Block Off "+CommonUtil.status_messages.update_success)
        } else {
            message.error(errormessage ?? CommonUtil.status_messages.error)
        }
     
        dataUpdaterFunc(resultStatus)
    }

    )
    );



}

static deleteBlockOffAppointmentById = (id , dataUpdaterFunc=()=>{}) => {
    //console.log(" the input data ==>",inputData);
    let resultStatus = true;
    let errormessage = "";
    trackPromise(
     axios.delete("/api-route/api/v1/service/appt-block/"+id)
    .then(response => response.data)
    .catch(err => {
        if(err.status === 400)errormessage = err.data;
        resultStatus=false;})
    .finally( () => {
        if(resultStatus){
            message.success("Block Off "+CommonUtil.status_messages.delete_success)
        } else {
            message.error(errormessage ?? CommonUtil.status_messages.error)
        }
     
        dataUpdaterFunc(resultStatus)
    }

    )
    );



}


static getBlockOffAppointmentById= (id,dataUpdaterFunc=()=>{}) => {
        
    // dataUpdaterFunc([]);
     let resultantData = {}
    
     trackPromise(
     axios.get("/api-route/api/v1/service/appt-block/"+id)
     .then(response => resultantData=response.data)
         .catch(err => {})
         .finally(() => {
            
             dataUpdaterFunc(resultantData);
 
         })
     );
 
 }


 
 static confirmAppointmentInEmail = (token, dataUpdaterFunc=()=>{}) => {
    let resultStatus = false;
    let responseData={};
    trackPromise(
     axios.post("/api-route/appt-confirm?token="+token)
    .then(response => 
        { resultStatus= true ;responseData=response.data} )
    .catch(err => {
        
        resultStatus=false;})
    .finally( () => {
        //console.log("===>",responsedata)
        dataUpdaterFunc({responseData,resultStatus})
    }

    )
    );

}

static updateAppointmentNotesById = (id,inputData , dataUpdaterFunc=()=>{}) => {
    let resultStatus = true;
    let errormessage = "";
    // {
    //     "stTime": "yyyy-MM-dd HH:mm"
    //   }
    trackPromise(
     axios.put("/api-route/api/v1/service/appt/"+id+"/note" , inputData)
    .then(response => response.data)
    .catch(err => {
        if(err.status === 400)errormessage = err.data;
        resultStatus=false;})
    .finally( () => {
        if(resultStatus){
            message.success("Appointment reason "+CommonUtil.status_messages.update_success)
        } else {
            message.error(errormessage ?? CommonUtil.status_messages.error)
        }
     
        dataUpdaterFunc(resultStatus)
    }

    )
   
    );

}




}